<template>
  <vx-card title="เมคยอดถอน (PGM)">
    <div class="vx-row">
      <div class="vx-col md:w-2/2 w-full">
        <vs-input class="w-full my-4" v-validate="'required'" label="username" placeholder="กรอก username"
          v-model="username" name="username" />
        <span class="text-danger text-sm" v-show="errors.has('username')">{{
          errors.first("username")
        }}</span>
        <vs-divider />

        <p class="mt-5">ช่วงเวลาถอน</p><flat-pickr :config="configDateTimePicker" v-model="datetime" placeholder="Date Time" />
        <vs-input class="w-full mt-2 my-4" v-validate="'required'" label="datetime" v-model="datetime"
          name="datetime" />
        <span class="text-danger text-sm" v-show="errors.has('datetime')">{{
          errors.first("datetime")
        }}</span>

        <vs-divider />

        <vs-input class="w-full my-4" v-validate="'required|decimal'" label="beforeBalance"
          placeholder="กรอกเงินลูกค้าก่อนถอน" v-model="beforeBalance" name="beforeBalance" />
        <span class="text-danger text-sm" v-show="errors.has('beforeBalance')">{{
          errors.first("beforeBalance")
        }}</span>

        <vs-input class="w-full my-4" v-validate="'required|decimal'" label="amount" placeholder="กรอกยอดถอน"
          v-model="amount" name="amount" />
        <span class="text-danger text-sm" v-show="errors.has('amount')">{{
          errors.first("amount")
        }}</span>
        <div class="mb-2">
        <vs-button color="primary" size="small" class="mx-1" @click="setAmount(500000)">500K</vs-button>
        <vs-button color="primary" size="small" class="mx-1" @click="setAmount(250000)">250k</vs-button>
        <vs-button color="primary" size="small" class="mx-1" @click="setAmount(100000)">100k</vs-button>
        <vs-button color="primary" size="small" class="mx-1" @click="setAmount(50000)">50k</vs-button>
      </div>


        <vs-input class="w-full my-4" v-validate="'required|decimal'" label="afterBalance (beforeBalance - amount)"
          v-model="afterBalance" name="afterBalance" disabled />
        <span class="text-danger text-sm" v-show="errors.has('afterBalance')">{{
          errors.first("afterBalance")
        }}</span>

        <vs-input class="w-full my-4" v-validate="'required'" label="info" placeholder="กรอก wd_info" v-model="info"
          name="info" />
        <span class="text-danger text-sm" v-show="errors.has('info')">{{
          errors.first("info")
        }}</span>
      </div>
    </div>
    <vs-divider />
    <div v-if="history.length > 0">
      <h1>Combo! {{ history.length }} hit{{ history.length > 1 ? 's' : '' }}</h1>
      <div v-for="(h, index) in history" :key="'history-of-makewd-' + index">
        <h2>#{{ index + 1 }}</h2>
        <p>{{ h }}</p>
      </div>
    </div>
    <div class="mt-8 flex flex-wrap items-center justify-end">
      <vs-button :disabled="isLoading" color="success" class="ml-4 mt-2" @click="save(false)">ยืนยัน</vs-button>
      <vs-button :disabled="isLoading" color="primary" class="ml-4 mt-2" @click="save(true)">ยืนยันและทำ Combo</vs-button>
    </div>
  </vx-card>
</template>

<script>
import { Validator } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import vSelect from 'vue-select'
import axios from '../axios'

const dict = {
  custom: {
    username: {
      required: 'กรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น',
      max: 'กรอกตัวอักษรไม่เกิน20ตัว'
    }
  }
}
Validator.localize('en', dict)
export default {
  components: {
    vSelect,
    flatPickr

  },
  computed: {
    afterBalance () {
      // Your computation logic for afterBalance here
      return this.beforeBalance - this.amount
    }
  },
  data () {
    return {
      configDateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      status: false,
      username: '',
      amount: '',
      beforeBalance: '',
      info: 'Made by PGM',
      databank: [],
      datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
      history: [],
      isLoading: false,
      counter: 1
    }
  },
  async mounted () {
  },
  methods: {
    setAmount (amount) {
      this.amount = amount
    },
    setBeforeBalance (amount) {
      this.beforeBalance = amount
    },
    incrementDateTime () {
      this.datetime = moment.tz('Asia/Bangkok').add(this.counter, 'minutes').format('YYYY-MM-DD HH:mm')
      this.counter = this.counter + 1
    },
    async save (goNext = false) {
      this.isLoading = true
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const data = {
            datetime: this.datetime,
            username: this.username,
            amount: this.amount,
            beforeBalance: this.beforeBalance,
            afterBalance: this.afterBalance,
            info: this.info
          }

          await axios
            .post('makeWithdraw/add', data)
            .then((response) => (this.status = response.data))

          if (this.status.status === false) {
            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'บันทึกไม่สำเร็จ',
              text: this.status.info
            })
          } else {
            this.$vs.notify({
              time: 3000,
              color: 'success',
              position: 'top-right',
              icon: 'check_box',
              title: this.status.info
            })

            if (goNext) {
              // Save and go next
              this.goNext(data)
            } else {
              // Save and done
              setTimeout(() => {
                this.$router.push(`/member/${this.username}`)
              }, 1500)
            }
          }
        } else {
          this.$vs.notify({
            time: 8000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ไม่ได้กรอกข้อมูล',
            text: 'กรอกข้อมูลให้ครบถ้วน'
          })
        }
      })
      this.isLoading = false
    },
    goNext (data) {
      // Save history for reference
      this.history.push(data)


      this.setBeforeBalance(parseFloat(this.beforeBalance - this.amount))
      this.incrementDateTime()
    }
  }
}
</script>
